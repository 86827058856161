import React, { useState } from 'react';

import { isFalsy } from '@leapfinance/frontend-commons';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import LoginSignUp from '@/components/LoginSignUp';
import Onboarding from '@/components/Onboarding';
import { selectTokenExpiry } from '@/features/auth/authSlice';

const Login = () => {
  const router = useRouter();
  const [settingUp, setSettingUp] = useState<boolean>(true);
  const isTokenExpired = useAppSelector(selectTokenExpiry);

  React.useEffect(() => {
    if (isFalsy(isTokenExpired)) {
      setSettingUp(false);
      return;
    }
    if (!isTokenExpired) {
      router.push(`/applications`);
    } else {
      setSettingUp(false);
    }
  }, [isTokenExpired]);

  return !settingUp ? (
    <div className="flex items-center justify-center h-[100vh]">
      <Head>
        <title>Unisetu:Login</title>
      </Head>
      <div className="w-full grid grid-cols-1 md:grid-cols-[60%_40%] h-full">
        <div className="p-8 bg-white relative">
          <Onboarding />
        </div>
        <div className="bg-primary-50">
          <LoginSignUp />
        </div>
      </div>
    </div>
  ) : null;
};

export default Login;

Login.displayName = `Login page`;
